import React, { useContext } from 'react';
import Modal from '../Modal/Modal';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Divider, Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { Button } from '../Button/Button';
import FilterIcon from '../../../icons/Filter/FilterIcon';
import { Colors, VentoryColor } from '../../../util/color.util';
import ModalPane from '../Modal/ModalPane';
import { BaseFilter } from '../../filters/Filter/common/BaseFilter';
import { FilterProps } from '../../filters/Filter/common/filter.util';

interface FilterModalProps<T> {
  open: boolean;
  onClose: () => void;
  filter: BaseFilter<T>;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function FilterModal<T>({ open, onClose, filter, filterProps, setFilterProps }: FilterModalProps<T>) {
  const { currentCompany } = useContext(CompanyContext);

  const items = [...filter.filterOptions()];
  const left = items.slice(0, Math.floor(items.length / 2));
  const right = items.slice(Math.floor(items.length / 2), items.length);

  const showDivider = (index: number, columnSize: number) => {
    return index < columnSize - 1;
  };

  const filterItem = (item: BaseFilter<T>, index: number, columnSize: number) => {
    return (
      <Grid item xs={12} key={`key_container_${item.toString({ filterProps, setFilterProps })}`}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} key={item.toString({ filterProps, setFilterProps })}>
            {item.toComponent({ filterProps, setFilterProps })}
          </Grid>
          {showDivider(index, columnSize) ? (
            <Grid item xs={12} key={'divider' + item}>
              <Divider style={{ borderColor: VentoryColor.grey200 }} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const content = () => {
    if (items.length <= 3) {
      return (
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            {items.map((item, index) => filterItem(item, index, items.length))}
          </Grid>
        </Grid>
      );
    }

    const leftColumn = left.map((item, index) => filterItem(item, index, left.length));
    const rightColumn = right.map((item, index) => filterItem(item, index, right.length));

    return (
      <>
        <Grid item xs={6} pr={3}>
          <Grid container rowSpacing={2}>
            {leftColumn}
          </Grid>
        </Grid>
        {rightColumn.length ? (
          <>
            <Grid item xs={6} pl={3} className={'border-l ventory-light-border'}>
              <Grid container rowSpacing={2}>
                {rightColumn}
              </Grid>
            </Grid>
          </>
        ) : null}
      </>
    );
  };

  return (
    <Modal
      title={t().filter.singular.label}
      open={open}
      onClose={onClose}
      icon={<FilterIcon height={24} width={24} color={Colors.getPrimaryColor(currentCompany)} />}
      width={items.length <= 3 ? '600px' : undefined}
    >
      <ModalPane
        footer={
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <Button
                text={t().clearAll.singular.label}
                onClick={() => setFilterProps(new FilterProps())}
                style='primary'
                color={{ textColor: VentoryColor.deleteRed, backgroundColor: VentoryColor.deleteRedBackground }}
              />
            </Grid>
            <Grid item>
              <Button text={t().done.singular.label} onClick={onClose} style='secondary' />
            </Grid>
          </Grid>
        }
      >
        <Grid container alignContent={'space-between'} rowSpacing={2}>
          <Grid item xs={12}>
            <Grid container>{content()}</Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
