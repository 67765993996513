import React from 'react';
import { FilterProps, FilterStorageKey, replacer, reviver } from './filter.util';
import { DropdownButton } from '../../../common/DropdownButton/DropdownButton';

export interface BaseFilterProps {
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export abstract class BaseFilter<T> {
  className?: string;
  color?: string;
  key?: FilterStorageKey;

  constructor(key?: FilterStorageKey) {
    this.key = key;
  }

  abstract toLabel(props: BaseFilterProps): string;
  abstract toInnerContent(props: BaseFilterProps): string | JSX.Element;
  abstract toDropdownContent(props: BaseFilterProps): React.JSX.Element;
  abstract toIcon(props: BaseFilterProps): React.JSX.Element;
  abstract toCount(props: BaseFilterProps): number;

  filterOptions(): BaseFilter<any>[] {
    return [];
  }

  getFilterProps(): FilterProps {
    if (!this.key) return new FilterProps();
    const stored = localStorage.getItem(`VENTORY_FILTER_${this.key.toString()}`);
    if (!stored) return new FilterProps();
    return JSON.parse(stored, reviver) as FilterProps;
  }

  saveFilterProps(props: FilterProps) {
    if (!this.key) return;
    return localStorage.setItem(`VENTORY_FILTER_${this.key.toString()}`, JSON.stringify(props, replacer));
  }

  toString(props: BaseFilterProps): string {
    return this.toLabel(props);
  }

  // TODO: We should implement this differently, filters should be able to user other filters when filtering items
  filteredItems(items: T[], query: string, filterProps: FilterProps): T[] {
    return items.filter(item => this.search(item, query));
  }

  search(item: T, query: string): boolean {
    return true;
  }

  toComponent(props: BaseFilterProps) {
    return (
      <>
        <p className='mb-1'>{this.toLabel(props)}</p>
        <DropdownButton
          width='100%'
          content={this.toInnerContent(props)}
          dropdownContent={this.toDropdownContent(props)}
          startIcon={this.toIcon(props)}
          badge={this.toCount(props)}
        />
      </>
    );
  }
}
